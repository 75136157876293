import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
  
const forecastData_sample = {
    actual: ["389990", "402000", "410500", "398700", "415300", "428400", "440600", "455000", "467200", "479300", "490000", "505600"],
    forecast: ["848148", "860000", "870500", "855300", "880200", "890500", "905600", "915400", "928700", "940000", "955800", "965000"],
    ly: ["456901", "470000", "480500", "468900", "485300", "495600", "508900", "520400", "535600", "548700", "560200", "575800"],
    budget: ["806849", "815000", "825300", "810700", "835400", "845900", "860000", "870500", "885300", "895000", "910400", "925600"],
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

const FlashTotalRevChart = ({ forecastData, dataColors }) => {
    var chartLineColumnColors = getChartColorsArray(dataColors);
    var option_enabledOnSeries = [0, 1, 2, 3];
    var option_stroke_width = [4, 4, 4, 4];    

    const series = [
        {
            name: "Actual",
            type: "line",
            data: forecastData.actual,
        },
        {
            name: "Budget",
            type: "line",
            data: forecastData.budget,
        },
        {
            name: "Last Year",
            type: "line",
            data: forecastData.ly,
        },
        {
            name: "Forecast",
            type: "line",
            data: forecastData.forecast,
        },
    ];

    // Use months from forecastData for x-axis labels
    const customlabels = forecastData.months || []; // Use months key or default to an empty array

    const options = {
        chart: {
            toolbar: {
                show: true,
            },
            type: "bar",
        },
        stroke: {
            width: option_stroke_width,
        },
        title: {
            text: '',
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: option_enabledOnSeries,
            style: {
                colors: ['#405189','#f06548','#0ab39c','#f7b84b'], // Set text color to white
            },
            background: {
                enabled: true,
                foreColor: '#fff', // Ensures the text is white
                opacity: 1, // Adjust opacity as needed
                padding: 4, 
                borderWidth: 0,               
            },
        },
        labels: customlabels,
        xaxis: {
            type: "category",
            labels: {
                show: true,
            },
        },
        yaxis: {
            title: {
                text: "Revenue",
                style: {
                    fontWeight: 600,
                },
            },
        },
        colors: chartLineColumnColors,
        plotOptions: {
            bar: {
                columnWidth: "40%",
            },
        },
        tooltip: {
            enabled: true,
            shared: true,  // Ensure the tooltip is shared for the different series
            followCursor: true,  // Tooltip follows the cursor
            intersect: false,  // Ensure the tooltip appears even when the mouse isn't directly over a bar
            style: {
                fontSize: '14px',
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </React.Fragment>
    );
};

const LineRevenueChart = ({ forecastData, dataColors }) => {
    var MissingDataColors = getChartColorsArray(dataColors);

    const series = [
        {
            name: 'Actual',
            data: forecastData.actual,
        },
        {
            name: 'Budget',
            data: forecastData.budget,
        },
        {
            name: 'Last Year',
            data: forecastData.ly,
        },
        {
            name: 'Forecast',
            data: forecastData.forecast,
        },
    ];

    // Use months from forecastData for x-axis labels
    const customlabels = forecastData.months || []; // Default to an empty array if months is not present

    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
        },
        stroke: {
            width: [5, 5, 5, 5],
            curve: 'smooth',
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
        },
        labels: customlabels,
        xaxis: {
            labels: {
                show: true,
            },
        },
        colors: MissingDataColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export {
    FlashTotalRevChart,
    LineRevenueChart
};
