import { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Table,
    Input
} from "reactstrap"
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import Loader from "../../Components/Common/Loader";

const ForecastTab = (props) => {
    const {filterData, filterAjax, handleToastMessage}=props;
    /****** Forecast *******/
    const [serverPerformanceAjax_forecast, setServerPerformanceAjax_forecast] = useState(true);
    const [dateWiseData_forecast, setDateWiseData_forecast] = useState([]);
    const [aggregator_forecast, setAggregator_forecast] = useState("Month");
    const [year_forecast, setYear_forecast] = useState(new Date().getFullYear());
    const [mealPeriod_forecast, setMealPeriod_forecast] = useState(0);
    const [filtermealperiod_forecast, setFiltermealperiod_forecast] = useState([]);
    const [ChangedCover_forecast, setChangedCover_forecast] = useState({});
    const [ChangedRevenue_forecast, setChangedRevenue_forecast] = useState({});
    const [originalData_forecast, setOriginalData_forecast] = useState(dateWiseData_forecast);
    const [hideOldDiv_forecast, setHideOldDiv_forecast] = useState(false);
    
    useEffect(() => {
        if (filterData.mealperiod) {
            setFiltermealperiod_forecast(filterData.mealperiod);
        }
    }, [filterAjax, filterData]);

    useEffect(()=>{
        getInfo_forecast(mealPeriod_forecast, aggregator_forecast, year_forecast);
    },[mealPeriod_forecast,aggregator_forecast,year_forecast])

    const getInfo_forecast = (mealPeriod_forecast, aggregator_forecast, year_forecast) => {
        setServerPerformanceAjax_forecast(true);
        setHideOldDiv_forecast(false);
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.forecast_list_op,
            mealPeriod: mealPeriod_forecast,
            budgetSelectRange: aggregator_forecast,
            budgetYear: year_forecast
        }).then(response => {
            if (response.status === 'ok') {
                setDateWiseData_forecast(response.data.data);
                setOriginalData_forecast(response.data.data);
                setServerPerformanceAjax_forecast(false);
            } else {
                // console.log(response);
            }
        });
    }

    const handleaggregator_forecast = (event) => {
        setAggregator_forecast(event.target.value);
    }

    const handleYear_forecast = (event) => {
        setYear_forecast(event.target.value);
    }

    const handleMealPeriod_forecast = (event) => {
        setMealPeriod_forecast(event.target.value);
    }

    const handleInputCover_forecast = (event, date) => {
        setHideOldDiv_forecast(true);
        const { name, value } = event.target;
        setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedCover_forecast({ ...ChangedCover_forecast, [date]: true });
        if (value != '0' && value != '') {
            setCheckboxChecked_forecast(date, true);
        } else {
            setCheckboxChecked_forecast(date, false);
        }

        // Calculate Average Check if Revenue is available
        const forecast_revenue = dateWiseData_forecast.find(data => data.from_date_display == date)?.forecast_revenue || 0;
        if (forecast_revenue && value != '' && value != '0') {
            const forecast_avg_check = parseFloat(forecast_revenue) / parseFloat(value);
            // console.log('Average Check:', forecast_avg_check);
            setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
                if (data.from_date_display == date) {
                    data['forecast_avg_check'] = forecast_avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputRevenue_forecast = (event, date) => {
        setHideOldDiv_forecast(true);
        const { name, value } = event.target;
        setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
            if (data.from_date_display == date) {
                data[name] = value;
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedRevenue_forecast({ ...ChangedRevenue_forecast, [date]: true });
        if (value != '0' && value != '') {
            setCheckboxChecked_forecast(date, true);
        } else {
            setCheckboxChecked_forecast(date, false);
        }

        // Calculate Average Check if Covers is available
        const forecast_covers = dateWiseData_forecast.find(data => data.from_date_display == date)?.forecast_covers || 0;
        if (forecast_covers && value != '' && value != '0') {
            const forecast_avg_check = parseFloat(value) / parseFloat(forecast_covers);
            // console.log('Average Check:', forecast_avg_check);
            setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
                if (data.from_date_display == date) {
                    data['forecast_avg_check'] = forecast_avg_check.toFixed(2);
                }
                return data;
            }));
            // Set the Average Check in your state or perform further actions as needed
        }
    }

    const handleInputforecast_avg_check = (event, date) => {
        const { name, value } = event.target;
        setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
            if (data.from_date_display == date) {
                data['forecast_avg_check'] = value;
            }
            return data;
        }));   
        const forecast_covers = dateWiseData_forecast.find(data => data.from_date_display == date)?.forecast_covers || 0;
        const forecast_revenue = parseFloat(forecast_covers) * parseFloat(value); 
        setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
            if (data.from_date_display == date) {
                data['forecast_revenue'] = forecast_revenue.toFixed(2);
            }
            return data;
        }));
        // Update the changed inputs state
        setChangedCover_forecast({ ...ChangedCover_forecast, [date]: true });
        if (value != '0' && value != '') {
            setCheckboxChecked_forecast(date, true);
        } else {
            setCheckboxChecked_forecast(date, false);
        }
    }
        
    const handleSubmit_forecast = () => {
        setServerPerformanceAjax_forecast(true);
        const checkedRowsData_forecast = [];
        for (let i = 0; i < dateWiseData_forecast.length; i++) {

            let valId = 'forecast_checkbox_' + i;
            var checkbox = document.getElementById(valId);
            if (checkbox.checked) {
                checkedRowsData_forecast.push({
                    "br_id": dateWiseData_forecast[i]['br_id'],
                    "covers": dateWiseData_forecast[i]['forecast_covers'],
                    "revenue": dateWiseData_forecast[i]['forecast_revenue']
                });
            } else {
                // console.log('Checkbox is not checked');
            }

        }
        // console.log("Checked Rows Data:", checkedRowsData_forecast);
        postDataAPI({
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.save_forecast_data_op,
            mealPeriod_forecast: mealPeriod_forecast,
            budgetSelectRange: aggregator_forecast,
            budgetYear: year_forecast,
            forecast_data: checkedRowsData_forecast
        }).then(response => {
            if (response.status === "ok") {
                handleToastMessage('success', response.message);
                setServerPerformanceAjax_forecast(false);
                setTimeout(() => {
                    // window.location.reload();
                }, 2000);
                getInfo_forecast(mealPeriod_forecast, aggregator_forecast, year_forecast);
            } else {
                handleToastMessage('error', response.message);
            }
        });
    };

    const setCheckboxChecked_forecast = (date, checked) => {
        setDateWiseData_forecast(dateWiseData_forecast.map((data) => {
            if (data.from_date_display === date) {
                data.isChecked_forecast = checked;
            }
            return data;
        }));
    };

    // console.log(dateWiseData);

    return (
        <>
        <Row>
            <Col lg={3} >
                <select value={aggregator_forecast} onChange={handleaggregator_forecast} className="form-select mb-3">
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                </select>
            </Col>
            <Col lg={3}>
                <select value={year_forecast} onChange={handleYear_forecast} className="form-select mb-3">
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </Col>
            <Col lg={3}>
                <select
                    className="form-select"
                    value={mealPeriod_forecast}
                    id="mealPeriod_forecast"
                    onChange={handleMealPeriod_forecast}
                >
                    <option value="0">All Meal Periods</option>
                    {!filterAjax &&
                        filtermealperiod_forecast.length > 0 &&
                        filtermealperiod_forecast.map((item) => {
                            return (
                                <option key={item.sp_id} value={item.sp_id}>
                                    {item.title +
                                        " (" +
                                        item.start_time +
                                        " To " +
                                        item.end_time +
                                        ")"}
                                </option>
                            );
                        })}
                </select>
            </Col>
            <Col lg={3}>
                <button className="btn btn-primary"
                    style={{ float: 'right' }}
                    onClick={handleSubmit_forecast}>
                    Save
                </button>
            </Col>
        </Row>
        {serverPerformanceAjax_forecast && <Loader />}
        <div
            className={
                serverPerformanceAjax_forecast
                    ? "opacity-0"
                    : ""
            }
        >
            <div className="table-responsive">
                <Table className="table-bordered table-nowrap text-center align-middle mb-0 table-sm">
                    <thead style={{ position: 'sticky', top: '-2px', background: '#fff', zIndex: '10' }}>
                        <tr>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>{aggregator_forecast == 'Month' ? 'Months' : 'Date'}</th>
                            <th className="text-center" scope="col" style={{ width: '26%' }}>Covers</th>
                            <th className="text-center" scope="col" style={{ width: '26%' }}>Revenue</th>
                            <th className="text-center" scope="col" style={{ width: '20%' }}>Avg Check</th>
                            <th className="text-center" scope="col" style={{ width: '10%' }}>Apply</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: '400px', background: '#fff', display: 'table-caption' }}>
                        {dateWiseData_forecast.map((data, index) => {
                            const checkboxId_forecast = `forecast_checkbox_${index}`;
                            return (
                                <tr key={data.from_date_display} style={{ width: '100%', display: 'inline-table' }}>
                                    <td style={{ width: '20%' }}>{data.from_date_display}</td>
                                    <td style={{ width: '26%' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <Input
                                                    name="forecast_covers"
                                                    className="form-control form-control-sm"
                                                    type="number"
                                                    value={data.forecast_covers}
                                                    style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                                    onChange={(event) => handleInputCover_forecast(event, data.from_date_display)}
                                                // onBlur={(event) => handleInputCover_forecast(event, data.from_date_display)}
                                                />
                                            </div>
                                            {hideOldDiv_forecast && (
                                                <div style={{ width: '50%' }}>
                                                    {ChangedCover_forecast[data.from_date_display] && (
                                                        <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                            {originalData_forecast.find(d => d.from_date_display === data.from_date_display) && originalData_forecast.find(d => d.from_date_display === data.from_date_display).oCovers !== '' ? (
                                                                <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                    Old : {originalData_forecast.find(d => d.from_date_display === data.from_date_display).oCovers}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ width: '26%' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <Input
                                                    name="forecast_revenue"
                                                    className="form-control form-control-sm"
                                                    type="number"
                                                    value={data.forecast_revenue}
                                                    style={{ maxWidth: "150px", margin: 'auto', fontSize: '13px' }}
                                                    onChange={(event) => handleInputRevenue_forecast(event, data.from_date_display)}
                                                // onBlur={(event) => handleInputRevenue_forecast(event, data.from_date_display)}
                                                />
                                            </div>
                                            {hideOldDiv_forecast && (
                                                <div style={{ width: '50%' }}>
                                                    {ChangedRevenue_forecast[data.from_date_display] && (
                                                        <div style={{ marginLeft: '20px', marginTop: '3px' }}>
                                                            {originalData_forecast.find(d => d.from_date_display === data.from_date_display) && originalData_forecast.find(d => d.from_date_display === data.from_date_display).oRevenue !== '' ? (
                                                                <div style={{ background: '#f06548', padding: '3px 10px', borderRadius: '4px', color: '#fff', width: 'max-content' }}>
                                                                    Old : {originalData_forecast.find(d => d.from_date_display === data.from_date_display).oRevenue}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ width: '15%' }}>
                                        <Input
                                            name="forecast_avg_check"
                                            className="form-control form-control-sm"
                                            type="number"
                                            value={data.forecast_avg_check}
                                            style={{ maxWidth: "150px", margin: "auto", fontSize: '13px' }}
                                            onChange={(event) => handleInputforecast_avg_check(event, data.from_date_display)}
                                        // onBlur={(event) => handleInputforecast_avg_check(event, data.from_date_display)}
                                        />
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        {/* <i className="ri-code-box-line"></i> */}
                                        <div className="flex-shrink-0">
                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={checkboxId_forecast}
                                                    checked={data.isChecked_forecast}
                                                    style={{ margin: 'auto', display: 'block', pointerEvents: 'none' }}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    </>)
}
export default ForecastTab