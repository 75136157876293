import React, { useState, useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import { Col, Row, Card, CardHeader, CardBody, Button, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Input, Label, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { FLASH } from "../../helpers/data_helper";

import {
    currencyFormatWithAlert,
    currencyFormatWithoutDecimal,
    numberFormat,
    currencyFormat
} from "../../helpers/api_helper";

// Group headers
const groupedHeaders = [
    { name: 'REVENUE', columns: ['revenue', 'rev_bud', 'rev_ly'] },
    { name: 'COVERS', columns: ['covers', 'covers_bud', 'covers_ly'] },
    { name: 'AVG CHK', columns: ['avgchk', 'avgchk_bud', 'avgchk_ly'] },
    { name: 'OCCUPANCY', columns: ['occ', 'occ_bud', 'occ_ly'] },
];

// Custom header row component
const CustomHeaderRow = ({ columns, groupedHeaders }) => {
    const groupColors = [
        ['#ff4d4d', '#ff9999', '#ffe5e5'], // REVENUE shades (dark to light)
        ['#4db8ff', '#99d9ff', '#e5f4ff'], // COVERS shades (dark to light)
        ['#4dff4d', '#99ff99', '#e5ffe5'], // AVG CHK shades (dark to light)
        ['#ff944d', '#ffc199', '#fff2e5'], // OCCUPANCY shades (dark to light)
    ];

    const groupedRow = (
        <tr>
            <th rowSpan="2" style={{ backgroundColor: '#f9f9f9' }}></th>
          {/*  {groupedHeaders.map((group, index) => (
                <th
                    key={index}
                    colSpan={group.columns.length}
                    style={{
                        textAlign: 'center',
                        backgroundColor: groupColors[index][1],
                        color: 'black',
                    }}
                >
                    {group.name}
                </th>
            ))} */}
        </tr>
    );

    const individualHeaders = (
        <tr>
            {columns.map((col, index) => {
                const groupIndex = groupedHeaders.findIndex((group) =>
                    group.columns.includes(col.selector)
                );
                const columnIndex = groupedHeaders[groupIndex].columns.indexOf(
                    col.selector
                );

                return (
                    <th
                        key={index}
                        style={{
                            backgroundColor: groupColors[groupIndex][columnIndex],
                            color: 'black',
                        }}
                    >
                        {col.name}
                    </th>
                );
            })}
        </tr>
    );

    return (
        <thead>
            {groupedRow}
            {individualHeaders}
        </thead>
    );
};

const FoodCapital = ({ mealPeriod, leftFilter }) => {
    const [forecastAjax, setForecastAjax] = useState(false);
    const [FoodCapitalData, setFoodCapitalData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const fromYear = new Date(leftFilter.from_date).getFullYear();
        const previousYear = fromYear - 1;

        const dynamicColumns = [
            { name: `${fromYear} Seat OCC%`, selector: 'occ', sortable: true },
            { name: `${previousYear} Seat OCC%`, selector: 'occ_bud', sortable: true },
            { name: '%vLY', selector: 'occ_ly', sortable: true },
        ];

        setColumns([
            { name: 'Food Capital', selector: 'name', sortable: true, width: '18%' },
            { name: 'Revenue', selector: 'revenue', sortable: true, width: '8%' },
            { name: '%vBUD', selector: 'rev_bud', sortable: true },
            { name: '%vLY', selector: 'rev_ly', sortable: true },
            { name: 'Covers', selector: 'covers', sortable: true },
            { name: '%vBUD', selector: 'covers_bud', sortable: true },
            { name: '%vLY', selector: 'covers_ly', sortable: true },
            { name: 'AVG CHK', selector: 'avgchk', sortable: true },
            { name: '%vBUD', selector: 'avgchk_bud', sortable: true },
            { name: '%vLY', selector: 'avgchk_ly', sortable: true },
            ...dynamicColumns,
        ]);
    }, [leftFilter]);

    useEffect(() => {
        setForecastAjax(true);
        postDataAPI({
            serviceName: FLASH.serviceName,
            op: FLASH.op_flash_food_capital,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod
        }).then((response) => {
            setForecastAjax(false);
            if (response.status === "ok") {
                setFoodCapitalData(response.data.data);
            }
        });
    }, [mealPeriod, leftFilter]);

    return (
        <Card>
            <CardHeader className="align-items-center">
                <h4 className="card-title mb-0">Food Capital</h4>
            </CardHeader>
            {forecastAjax && <Loader />}
            <div className="table-responsive">
                <table className="table table-bordered" style={{ backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}>
                    <CustomHeaderRow columns={columns.slice(1)} groupedHeaders={groupedHeaders} />
                    <tbody>
                        {FoodCapitalData.map((row, index) => (
                            <tr key={index}>
                                {columns.map((col, idx) => (
                                    <td key={idx}>
                                        {col.selector === 'revenue'
                                            ? currencyFormatWithoutDecimal(row[col.selector], '$')
                                            : col.selector === 'avgchk'
                                                ? currencyFormat(row[col.selector], '$')
                                                : row[col.selector]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default FoodCapital;
