import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const ForeCastCover = ({forecastData, dataColors, start_dt, end_dt}) => {
    var chartLineColumnColors = getChartColorsArray(dataColors);
    var option_enabledOnSeries=[1];
    var option_stroke_width=[0,4,4];
    var yaxisData=[
        {
            seriesName: "Covers",
            title: {
                text: "Covers",
                style: {
                fontWeight: 600,
                },
            },
        },
        {
            seriesName: "Avg Checks",
            opposite: true,
            //show: false,
            title: {
                text: "Avg. Checks and RevPash",
                style: {
                fontWeight: 600,
                },
            },
        },
        {
            seriesName: "Avg Checks",
            opposite: true,
            title: {
                text: "RevPash",
                style: {
                fontWeight: 600,
                },
            },
        }
    ];
    const series = [
        {
            name: "Covers",
            type: "column",
            data: forecastData.covers,
        },
        {
            name: "Avg Checks",
            type: "line",
            data: forecastData.average_check,
        },
        {
            name: "RevPash",
            type: "line",
            data: forecastData.revPASH
        }
    ];
    
    // Date Logic
    const startDate = new Date(start_dt);
    const endDate = new Date(end_dt);
    const customlabels = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const day = currentDate.toLocaleString('en-us', { day: '2-digit' });
        const month = currentDate.toLocaleString('en-us', { month: 'short' });
        customlabels.push(`${day}-${month}`);
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    // var date = new Date();
    // var customlabels=['Today'];
    // for(let i=1;i<=6;i++){
    //     date.setDate(date.getDate() + 1);
    //     customlabels.push((date.toLocaleString('en-us', { day: '2-digit' }))+'-'+(date.toLocaleString('en-us', { month: 'short' })));
    // }

    const options = {
        chart: {
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: option_stroke_width,
        },
        title: {
          text: ''
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: option_enabledOnSeries,
        },
        labels: customlabels,
        xaxis: {
          type: "category",
        },
        yaxis: yaxisData,
        colors: chartLineColumnColors,
        plotOptions: {
            bar: {
                columnWidth: "40%",
            },
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
            />
        </React.Fragment>
    );
};


const LineRevenueChart = ({ revenueData,dataColors }) => {
    var MissingDataColors = getChartColorsArray(dataColors);
    const series = [{
        name: 'Actual',
        data: revenueData.actual
    }, {
        name: 'Budget',
        data: revenueData.budget
    }, {
        name: 'Last Year',
        data: revenueData.ly
    },
    {
        name: 'ForeCast',
        data: revenueData.forecast
    }];
    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            animations: {
                enabled: false
            }
        },
        stroke: {
            width: [5, 5, 5,5],
            curve: 'smooth'
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
          },
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November","December"],
        xaxis: {},
        colors: MissingDataColors
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export {
    ForeCastCover,
    LineRevenueChart
};
