/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import ForeCastPreviewCardHeader from "../../Components/Common/ForeCastPreviewCardHeader";
import { FlashChartRevOcc } from "./FlashChartRevOcc";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { FLASH } from "../../helpers/data_helper";

const ForeCastRevOcc = ({ mealPeriod, leftFilter }) => {
    const [forecastAjax, setForecastAjax] = useState(false);
    const [start_dt, setstart_dt] = useState("");
    const [end_dt, setend_dt] = useState("");
    const [forecastData, setForecastData] = useState({
        revenue: [],
        seat_occupancy: [],
        table_occupancy: []
    });

    let date = new Date();
    date.setDate(date.getDate() - 1);

    let date_second = new Date();
    date_second.setDate(date_second.getDate() - 1);

    useEffect(()=>{
    
        // console.log("Selected Meal Period:", mealPeriod);
        // console.log("Selected Date:", leftFilter);

        setForecastAjax(true);
        postDataAPI({
            serviceName: FLASH.serviceName,
            op: FLASH.op_seat_occ,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod
        }).then((response) => {
            setForecastAjax(false);
            if (response.status === "ok") {                           
                setForecastData({
                    revenue: response.data.data.revenue || [],  
                    seat_occupancy: response.data.data.seat_occupancy || [],
                    table_occupancy: response.data.data.table_occupancy || []  
                });   
                setstart_dt(response.data.data.start_dt);
                setend_dt(response.data.data.end_dt);                                             
            } else {
                // console.log(response);
            }
        });
        
    }, [mealPeriod, leftFilter]);
    
    return (
        <React.Fragment>
            <Card>
                <ForeCastPreviewCardHeader
                title="Forecast - Revenue, Seat Occupancy  and  Table Occupancy"
                toggleText="Show Table"
                />
                <CardBody>
                {forecastAjax && <Loader />}
                <div className={forecastAjax ? "opacity-0" : ""}>
                    <div className="live-preview">
                    <FlashChartRevOcc
                        forecastData={forecastData}
                        dataColors='["--vz-primary", "--vz-danger", "#FFEB3B", "--vz-warning", "--vz-info", "--vz-dark"]'
                        start_dt={start_dt}
                        end_dt={end_dt}
                    />
                    </div>
                    <div className="d-none code-view">
                    <div className="table-responsive table-card">
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead className="text-muted table-light">
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col">Revenue</th>
                                <th scope="col">Seat Occupancy</th>
                                <th scope="col">Table Occupancy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(forecastData.revenue || []).map(
                            (item, key) => {
                                date.setDate(date.getDate() + 1);
                                return (
                                <tr key={key}>
                                    <td>
                                    {date.toLocaleString("en-us", {
                                        day: "2-digit",
                                    }) +
                                        " " +
                                        date.toLocaleString("en-us", {
                                        month: "short",
                                        })}
                                    </td>
                                    <td>{item}</td>
                                    <td>
                                    {forecastData.seat_occupancy[key] ?? ""}
                                    </td>
                                    <td>
                                    {forecastData.table_occupancy[key] ?? ""}
                                    </td>
                                </tr>
                                );
                            }
                            )}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ForeCastRevOcc;
