import {  useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    TabContent,
    Nav,
    NavItem,
    NavLink,
    TabPane,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import ForecastTab from "./ForecastTab";
import LastYearTab from "./LastYearTab";
import BudgetTab from "./BudgetTab";

const ForecastBudget = () => {

    const handleToastMessage = (type, message) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "error":
                toast.error(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" });
                break;
            case "success":
                toast.success(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
                break;
        }
    }

    
    const [activeTab, setActiveTab] = useState("1");   

    const { filterData, filterAjax } = useSelector(
        (state) => ({
            filterData: state.Dashboard.filterData,
            filterAjax: state.Dashboard.filterAjax
        })
    );


    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Nav tabs className="nav-tabs mb-3">
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { setActiveTab("1"); }} >
                                            Budget
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { setActiveTab("2"); }} >
                                            Forecast
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3", })} onClick={() => { setActiveTab('3'); }} >
                                            Historical
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="text-muted">
                                    <TabPane tabId="1" id="budget">
                                        {activeTab=="1" && <BudgetTab handleToastMessage={handleToastMessage} filterAjax={filterAjax} filterData={filterData} />}
                                    </TabPane>

                                    <TabPane tabId="2" id="forecast">
                                        {activeTab=="2" && <ForecastTab handleToastMessage={handleToastMessage} filterAjax={filterAjax} filterData={filterData} />}
                                    </TabPane>

                                    <TabPane tabId="3" id="lastyear">
                                        {activeTab=="3" && <LastYearTab handleToastMessage={handleToastMessage} filterAjax={filterAjax} filterData={filterData} />}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ForecastBudget