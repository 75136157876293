import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const FlashChartRevOcc = ({forecastData, dataColors, start_dt, end_dt}) => {
    var chartLineColumnColors = getChartColorsArray(dataColors);
    var option_enabledOnSeries=[1];
    var option_stroke_width=[0,4,4];
    var yaxisData=[
        {
            seriesName: "Covers",
            title: {
                text: "Revenue",
                style: {
                fontWeight: 600,
                },
            },
        },
        {
            seriesName: "Seat Occupancy",
            opposite: true,
            //show: false,
            title: {
                text: "Seat/Table Occupancy",
                style: {
                fontWeight: 600,
                },
            },
            min:0,
            max: 100,
        },
        {
            seriesName: "Seat Occupancy",
            opposite: true,
            title: {
                text: "Revenue",
                style: {
                fontWeight: 600,
                },
            },
        }
    ];
    const series = [
        {
            name: "Revenue",
            type: "column",
            data: forecastData.revenue,
        },
        {
            name: "Seat Occupancy",
            type: "line",
            data: forecastData.seat_occupancy,
        },
        {
            name: "Table Occupancy",
            type: "line",
            data: forecastData.table_occupancy
        }
    ];
    
    // Date Logic
    const startDate = new Date(start_dt);
    const endDate = new Date(end_dt);
    const customlabels = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const day = currentDate.toLocaleString('en-us', { day: '2-digit' });
        const month = currentDate.toLocaleString('en-us', { month: 'short' });
        customlabels.push(`${day}-${month}`);
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
    
    // var date = new Date();
    // var customlabels=['Today'];
    // for(let i=1;i<=6;i++){
    //     date.setDate(date.getDate() + 1);
    //     customlabels.push((date.toLocaleString('en-us', { day: '2-digit' }))+'-'+(date.toLocaleString('en-us', { month: 'short' })));
    // }

    const options = {
        chart: {
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: option_stroke_width,
        },
        title: {
          text: ''
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: option_enabledOnSeries,
        },
        labels: customlabels,
        xaxis: {
          type: "category",
        },
        yaxis: yaxisData,
        colors: chartLineColumnColors,
        plotOptions: {
            bar: {
                columnWidth: "40%",
            },
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
            />
        </React.Fragment>
    );
};

const LineColumnArea = ({forecastBudget, forecastLastYear, forecastData, dataColors}) => {
    var chartLineAreaMultiColors = dataColors;
    var option_stroke_width=[0, 2, 5];
    var option_fill_opacity=[0.85, 0.25, 1];
    var seat_occupancy_max=Math.max(...forecastData.seat_occupancy,...forecastData.seat_occupancy)+10;
    var RevPASH_max=Math.max(...forecastData.table_occupancy)+10;
    if(forecastBudget===true && forecastLastYear===true){
        seat_occupancy_max=Math.max(seat_occupancy_max,...forecastData.seat_occupancy_budget,...forecastData.average_check_budget,...forecastData.seat_occupancy_lastyear,...forecastData.average_check_lastyear)+30;
        RevPASH_max=Math.max(...forecastData.table_occupancy,...forecastData.revPASH_budget,...forecastData.revPASH_lastyear)+30;
    }else if(forecastBudget===true){
        seat_occupancy_max=Math.max(seat_occupancy_max,...forecastData.seat_occupancy_budget,...forecastData.average_check_budget)+20;
        RevPASH_max=Math.max(...forecastData.table_occupancy,...forecastData.revPASH_budget)+20;
    }else if(forecastLastYear===true){
        seat_occupancy_max=Math.max(seat_occupancy_max,...forecastData.seat_occupancy_lastyear,...forecastData.average_check_lastyear)+20;
        RevPASH_max=Math.max(...forecastData.table_occupancy,...forecastData.revPASH_lastyear)+20;
    }
    var yaxisData=[
        {
            seriesName: "Seat Occupancy",
            title: {
                text: "Occupancy(%)"
            },
            min:0,
            max: 100,
        },
        {
            seriesName: "Average Check",
            show: false,
            min: 0,
            max: 100,
        },
        {
            seriesName: "RevPASH",
            opposite: true,
            title: {
                text: "RevPASH"
            },
            max: RevPASH_max,
        }
    ];
    const series = [
        {
            name: "Seat Occupancy",
            type: "column",
            data: forecastData.seat_occupancy,
        },
        {
            name: "Average Check",
            type: "area",
            data: forecastData.seat_occupancy,
        },
        {
            name: "RevPASH",
            type: "line",
            data: forecastData.table_occupancy,
        },
    ];
    if(forecastBudget===true){
        series.push({
            name: "Budget Seat Occupancy",
            type: "column",
            data: forecastData.seat_occupancy_budget,
        });
        series.push({
            name: "Budget Average Check",
            type: "area",
            data: forecastData.average_check_budget,
        });
        series.push({
            name: "Budget RevPASH",
            type: "line",
            data: forecastData.revPASH_budget,
        });
        option_stroke_width.push(0, 2, 5);
        option_fill_opacity.push(0.85, 0.25, 1);
        yaxisData.push({
            seriesName: "Budget Seat Occupancy",
            show: false,
            min: 0,
            max: seat_occupancy_max,
        });
        yaxisData.push({
            seriesName: "Budget Average Check",
            show: false,
            min: 0,
            max: seat_occupancy_max,
        });
        yaxisData.push({
            seriesName: "Budget RevPASH",
            show: false,
            min: 0,
            max: RevPASH_max,
        });
    }
    if(forecastLastYear===true){
        series.push({
            name: "LastYear Seat Occupancy",
            type: "column",
            data: forecastData.seat_occupancy_lastyear,
        });
        series.push({
            name: "LastYear Average Check",
            type: "area",
            data: forecastData.average_check_lastyear,
        });
        series.push({
            name: "LastYear RevPASH",
            type: "line",
            data: forecastData.revPASH_lastyear,
        });
        option_stroke_width.push(0, 2, 5);
        option_fill_opacity.push(0.85, 0.25, 1);
        yaxisData.push({
            seriesName: "LastYear Seat Occupancy",
            show: false,
            min: 0,
            max: seat_occupancy_max,
        });
        yaxisData.push({
            seriesName: "LastYear Average Check",
            show: false,
            min: 0,
            max: seat_occupancy_max,
        });
        yaxisData.push({
            seriesName: "LastYear RevPASH",
            show: false,
            min: 0,
            max: RevPASH_max,
        });
    }
    var date = new Date();
    var customlabels=['Today'];
    for(let i=1;i<=6;i++){
        date.setDate(date.getDate() + 1);
        customlabels.push((date.toLocaleString('en-us', { day: '2-digit' }))+'-'+(date.toLocaleString('en-us', { month: 'short' })));
    }
    const options = {
        chart: {
            stacked: !1,
            toolbar: {
                show: true,
            },
        },
        stroke: {
            width:option_stroke_width,
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
            },
        },

        fill: {
            opacity: option_fill_opacity,
            gradient: {
                inverseColors: !1,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        labels: customlabels,
        markers: {
            size: 0,
        },
        xaxis: {
            type: "category",
        },
        yaxis: yaxisData,
        tooltip: {
            shared: !0,
            intersect: !1,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + "";
                    }
                    return y;
                },
            },
        },
        colors: chartLineAreaMultiColors,
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
            />
        </React.Fragment>
    );
};

export {
    FlashChartRevOcc,
    LineColumnArea
};
